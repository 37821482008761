@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Display:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;1,100;1,200;1,300;1,400;1,500;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Klee+One:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Forum&display=swap');

@font-face {
    font-family: 'Marck Script';
    src: url('../fonts/MarckScript-Regular.woff2') format('woff2'),
        url('../fonts/MarckScript-Regular.woff2') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bad Script';
    src: url('../fonts/BadScript-Regular.woff2') format('woff2'),
        url('../fonts/BadScript-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


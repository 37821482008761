.list {
    position: relative;
    
    &:before {
        content: '';
        width: 1px;
        height: 100%;
        background-color: var(--dark-color);
        position: absolute;
        top: 0;
        left: 50%;
        opacity: 0.8;
        z-index: 4;
    }
}

.column {
    flex: 0 0 50%;
    width: 50%;
    
    &:nth-child(1) {
        padding-right: 25px;
    }

    &:nth-child(2) {
        padding-left: 25px;
    }
}

.item {
    margin-bottom: var(--gap-5xl);
    display: flex;
    position: relative;
    text-align: left;
    word-wrap: break-word;

    &.reverse {
        text-align: right;

        .title {
            &:after {
                left: auto;
                right: -28px;
            }
        }
    }
}

.img {
    width: 140px;
    height: 140px;
    object-fit: cover;
    border-radius: 50%;
    margin: 0 auto;
    display: block;
}

.content {
}

.time {
    font-family: var(--title-font);
    font-size: 22px;
    color: var(--dark-color);
}

.title {
    font-family: var(--title-font);
    font-size: 18px;
    line-height: 1;
    color: var(--dark-color);
    margin-top: var(--gap-xxs);
    margin-bottom: var(--gap-xs);
    position: relative;

    &:after {
        content: '';
        width: 5px;
        height: 5px;
        background-color: var(--dark-color);
        border-radius: 50%;
        position: absolute;
        top: 10px;
        left: -27px;
    }
}

.text {
    font-family: var(--text-font);
    font-size: 16px;
    line-height: 1.2;
    color: var(--dark-color);
}
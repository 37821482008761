@import url('./css/reset.css');
@import url('./css/fonts.css');
@import url('./css/variables.css');

#root {
    max-width: var(--max-width);
    margin: 0 auto;
}

.container {
    text-align: center;
    padding: 0 15px;
    width: 100%;
}

.title {
    font-family: var(--title-font);
    font-weight: 500;
    font-style: italic;
    font-size: 30px;
    line-height: 0.9;
    color: var(--dark-color);
    margin-bottom: var(--gap-5xl);
}

.text {
    font-family: var(--text-font);
    font-size: 22px;
    line-height: 1.4;
    color: var(--dark-color);
    margin-bottom: var(--gap-2xl);
}
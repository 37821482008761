.container {
    background-color: rgba(255,255,255,0.95);
    box-shadow: 0px 0px 14px 0 rgba(15, 15, 15, 0.3);
    font-family: var(--title-font);
    font-size: 24px;
    color: var(--dark-color);
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 60px;
    z-index: 5;
    max-width: var(--max-width);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}

.audio {
    display: none;
}

.music {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    & span {
        display: block;
        font-size: 12px;
        font-family: var(--secondary-font);
    }
}
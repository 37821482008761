.list {
    display: flex;
    align-items: center;
    justify-content: center;
}

.item {
    flex: 0 0 43px;
    width: 43px;
    height: 43px;
    border-radius: 50%;
    margin: 0 2px;
}

.img {
    max-height: 50px;
}
.container {
    width: 100%;
    padding: var(--gap-s);
    background-color: var(--white-color);
    z-index: 10;
    text-align: center;
    box-shadow: 0px 0px 14px 0 rgba(15, 15, 15, 0.3);
}

.text {
    font-family: var(--text-font);
    font-size: 20px;
    line-height: 1.4;
    color: var(--dark-color);

    a {
        color: var(--dark-color);
        border-bottom: 1px dashed var(--dark-color);
    }
}
.container {
    width: 100%;
    height: calc(100vh - 100px);
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    &:after {
        content: '';
        background: rgb(60,60,60);
        background: linear-gradient(0deg, rgba(38, 38, 38, 1) 0%, rgba(0,0,0,0) 80%);
        z-index: 2;
        position: absolute;
        top: 25%;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.content {
    position: relative;
    z-index: 3;
    padding: 15px 15px;
    text-align: center;
}

.title {
    font-family: 'Noto Serif Display', serif;
    font-weight: 300;
    font-style: italic;
    color: var(--white-color);
    line-height: 1.2;
    font-size: 100px;
}

.names {
    font-family: var(--title-font);
    font-weight: 300;
    font-style: italic;
    color: var(--white-color);
    line-height: 1;
    font-size: 24px;
}

.separator {
    width: 30%;
    height: 2px;
    background-color: var(--white-color);
    margin: 15px 0;
    display: inline-block;
}

.date {
    font-family: var(--title-font);
    font-weight: 300;
    font-style: italic;
    color: var(--white-color);
    font-size: 34px;
    line-height: 1;
}

.arrow {
    margin-top: 25px;
    color: var(--white-color);
}
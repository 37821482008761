.container {
    margin-top: var(--section-outer-gap);
    padding-top: var(--section-inner-gap);
    position: relative;

    &:before {
        content: '';
        width: 30%;
        height: 1px;
        background-color: var(--dark-color);
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}
:root {
    --primary-font: 'Forum', serif;
    --secondary-font: 'Noto Serif Display', serif;
    --title-font: 'Noto Serif Display', serif;
    --text-font: 'Forum', serif;

    /* color */
    --white-color: #fff;
    --dark-color: #201707;
    --gray-color: #555555;

    /* gap */
    --section-outer-gap: 100px;
    --section-inner-gap: 100px;
    --gap-xxs: 5px;
    --gap-xs: 10px;
    --gap-s: 15px;
    --gap-m: 20px;
    --gap-l: 25px;
    --gap-2xl: 30px;
    --gap-3xl: 35px;
    --gap-4xl: 40px;
    --gap-5xl: 45px;

    /* width */
    --max-width: 768px;
}
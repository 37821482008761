.bg {
    width: 100%;
    height: calc(100vh - 60px);
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 25%;
    position: relative;

    &:after {
        content: '';
        background: rgb(60,60,60);
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0,0,0,0) 80%);
        z-index: 2;
        position: absolute;
        top: 20%;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.content {
    width: 100%;
    padding: 0 15px;
    position: relative;
    z-index: 3;
    text-align: center;
}

.title {
    font-family: var(--text-font);
    color: var(--white-color);
    font-size: 30px;
    line-height: 1.2;
    margin-bottom: var(--gap-xxs);
}

.text {
    font-family: var(--text-font);
    color: var(--white-color);
    font-size: 20px;
    line-height: 1.2;
    margin-bottom: var(--gap-2xl);
}

.date {
    font-family: var(--text-font);
    color: var(--white-color);
    font-size: 24px;
    line-height: 1.2;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;

    & div {
        flex: 0 0 25%;
        width: 25px;
        position: relative;

        &:after {
            content: '';
            width: 1px;
            height: 100%;
            background-color: var(--white-color);
            position: absolute;
            top: 0;
            right: 0;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }
    }

    & span {
        display: block;
        font-size: 14px;
    }
}
